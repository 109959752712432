import axios from "axios";
import store from "../store";
import { getAuth } from "firebase/auth";

const api = axios.create({
	baseURL: "https://api.prit.app/",
	headers: {
		"content-type": "application/json",
	},
});

api.interceptors.request.use(async (config) => {
	let accessToken= localStorage.getItem('accessToken');;
	let token = accessToken ? accessToken : null;

	const currentUser = getAuth().currentUser;
	if (currentUser !== null) {
		token = currentUser.accessToken;
		config.headers.Authorization = `Bearer ${token}`;
	} else if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	} else {
		config.headers.Authorization = null;
	}

	return config;
});

export default api;
