import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
	title: {
		color: "#393762",
		fontSize: 60,
		lineHeight: "0.9",
		letterSpacing: "-0.66px",
	},
	container: {
		margin: "auto",
		textAlign: "center",
		width: "50%",
		padding: "10px",
	},
	field: {
		width: "300px",
		fontSize: "300px",
	},
	error: {
		color: "#ff3b30",
		fontSize: "15px",
	},
	icon: {
		cursor: "pointer",
		color: "#3689ea",
		transform: "scale(2)",
	},
	littleGray: {
		color: "rgba(161, 161, 161, 1)",
		fontSize: 18,
		paddingBottom: 10,
	},

	strongGray: {
		color: "rgba(71, 71, 71, 1)",
		fontSize: 38,
		paddingBottom: 10,
	},

	BillWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		border:' 1px dashed #D9D9D9',
		background: '#FFF',
		borderRadius: 1,
		padding: '20px 15px',
		alignSelf: 'center',
		maxHeight: '30%',
		justifyContent: 'space-between',
		overflow: 'hidden',
	},

	
  	BillToken: {
		fontFamily: 'CircularStd-Book',
		fontSize:'15´x',
		color:' #F06D6C',
		fontWeight: 500,
		flex: 1,
	},
}));

