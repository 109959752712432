import Constants from "../../helpers/Constants";

const meReducer = (state = { me: null }, action) => {
	switch (action.type) {
		case Constants.SET_ME:
			return {
				...state,
				me: action.payload.me,
			};
		default:
			return state;
	}
};

export default meReducer;
