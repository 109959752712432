import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import { InputAdornment, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { useNavigate } from "react-router";
import ButtonCommom from "../common/Button";
import BandeiraBRA from "assets/icons/BRA.png";
import BandeiraPOR from "assets/icons/POR.png";
import BandeiraEUA from "assets/icons/EUA.png";
import BandeiraING from "assets/icons/ING.png";
import BandeiraANG from "assets/icons/ANG.png";
import BandeiraFRA from "assets/icons/FRA.png";
import {
	telephoneMask,
	telephoneMaskPOR,
	telephoneMaskEUA,
	telephoneMaskING,
	telephoneMaskANG,
	telephoneMaskFRA,
} from "utils";
import Header from "../header";
import { Container } from "@mui/material";
import { useDispatch } from "react-redux";

const Login = (props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();


	const [telephoneNumber, setTelephoneNumber] = useState("");
	const [phoneDdi, setPhoneDdi] = useState(1);

	const handleChangeType = (e) => {
		setTelephoneNumber("");
		setPhoneDdi(e.target.value);
	};

	const getDdiNumber = (n) => {
		if (phoneDdi === 1) {
			return "55";
		} else if (phoneDdi === 2) {
			return "351";
		} else if (phoneDdi === 3) {
			return "1";
		} else if (phoneDdi === 4) {
			return "44";
		} else if (phoneDdi === 5) {
			return "244";
		} else if (phoneDdi === 6) {
			return "33";
		}
	};

	const handleChange = (e) => {
		if (phoneDdi === 1) {
			return telephoneMask(e);
		} else if (phoneDdi === 2) {
			return telephoneMaskPOR(e);
		} else if (phoneDdi === 3) {
			return telephoneMaskEUA(e);
		} else if (phoneDdi === 4) {
			return telephoneMaskING(e);
		} else if (phoneDdi === 5) {
			return telephoneMaskANG(e);
		} else if (phoneDdi === 6) {
			return telephoneMaskFRA(e);
		}
	};

	useEffect(() => {
		localStorage.setItem("logged", "false");
		document.getElementById("inputTelephoneNumber").focus();
		document.addEventListener("keypress", (e) => {
			if (e.key === "Enter") {
				document.getElementById("confirmButton").click();
			}
		});
	}, []);

	return (
		<Container maxWidth="sm" style={{ 
			background: '#ffffff',
			borderRadius: 3,
			border: 0,
			color: 'white',
			minWidth: '100vw',
			minHeight: '100vh',
			padding: '0 30px',
			boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
		  }}>
		<Fragment>
			<Header /> 
					<div style={{ marginTop: "5vh" }}>
						<div style={{ display: "flex", justifyContent: "center" }}>
							{/* <Typography style={{ fontSize: "calc(1.5vw + 5px)" }}>
								<strong className={classes.title}>Seja bem-vindo ao Prit!</strong>
							</Typography> */}
						</div>

						<div style={{ margin: " 15vw 0 2vw"}}>
							<Typography style={{ fontSize: "18px" }}>
								<strong className={classes.title}>Entrar com Telefone</strong>
							</Typography>

							<div style={{ display: "flex", marginTop: "calc(4vh + 10px)", width: "100%" }}>
								<Select
									style={{
										width: "22%",
										paddingLeft: "5px",
										color: "#8f8e8e",
										borderBottom: "none",
										marginTop: "15px",
									}}
									value={phoneDdi}
									label={phoneDdi}
									onChange={handleChangeType}>
									<MenuItem value={1}>
										<img style={{ width: " 20px", paddingRight: "8px" }} src={BandeiraBRA} alt="BandeiraBRA" />
										+55
									</MenuItem>
									<MenuItem value={2}>
										<img style={{ width: "  20px", paddingRight: "8px" }} src={BandeiraPOR} alt="BandeiraPOR" />
										+351
									</MenuItem>
									<MenuItem value={3}>
										<img style={{ width: " 20px", paddingRight: "8px" }} src={BandeiraEUA} alt="BandeiraEUA" />
										+1
									</MenuItem>
									<MenuItem value={4}>
										<img style={{ width: "  20px", paddingRight: "8px" }} src={BandeiraING} alt="BandeiraING" />
										+44
									</MenuItem>
									<MenuItem value={5}>
										<img style={{ width: " 20px", paddingRight: "8px" }} src={BandeiraANG} alt="BandeiraANG" />
										+244
									</MenuItem>
									<MenuItem value={6}>
										<img style={{ width: "  20px", paddingRight: "8px" }} src={BandeiraFRA} alt="BandeiraFRA" />
										+33
									</MenuItem>
								</Select>

								<TextField
									id="inputTelephoneNumber"
									value={telephoneNumber}
									size="normal"
									variant="standard"
									style={{
										flexGrow: "1",
									}}
									InputLabelProps={{ style: { fontSize: "15px" } }}
									className={classes.field}
									label="Digite seu telefone"
									onChange={(e) => {
										handleChange(e);
										setTelephoneNumber(e.target.value);
									}}
									type="tel"
									inputProps={{
									  pattern: "[0-9]*",
									  inputMode: "numeric"
									}}
								/>
							</div>
						</div>

						{/* <div style={{ display: "flex", justifyContent: "center", height: "30px" }}>
							{telephoneNumber.length > 0 && (telephoneNumber.length < 14 || telephoneNumber.charAt(5) !== "9") && (
								<Typography style={{ flexGrow: "1", textAlign: "center" }}>
									<h1 className={classes.error}>
										O número de telefone inserido não é valído. Por favor, tente novamente
									</h1>
								</Typography>
							)}
						</div> */}

						<div style={{ display: "flex", justifyContent: "center", padding: '8% 0 '}}>
							<Typography style={{ width: "70%", textAlign: "center" }}>
								<strong className={classes.message} style={{ fontSize: "15px" }}>
									Enviaremos um código via SMS para validar seu número de telefone.
								</strong>
							</Typography>
						</div>

						<div style={{ 
						bottom: 0, 
						width: "100%", 
						padding: "1vw",
						alignItems: "center",
						display: "flex"
					}}>
						<ButtonCommom
							id="confirmButton"
							toCode
							disable={telephoneNumber.length < 15 }
							params={{ telephoneNumber: telephoneNumber, ddi: getDdiNumber(phoneDdi) }}
							onClick={() => {}}
							style={{
								width: window.innerWidth > 600 ? "80%" : "100%",
								fontSize: window.innerWidth > 600 ? "1em" : "2em",
								height: '45px',
							}}
						/>

					</div>

					</div>
		</Fragment>
		</Container>
	);
};

export default Login;
