import Constants from "../../helpers/Constants";

const notificationsReducer = (state = {notifications: null}, action) => {
	switch (action.type) {
		case Constants.GET_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload.notifications,
			};
		default:
			return state;
	}
};

export default notificationsReducer;