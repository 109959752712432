import Constants from "../../helpers/Constants";

const paymentListReducer = (state = {paymentList: null}, action) => {
	switch (action.type) {
		case Constants.GET_PAYMENT_LIST:
			return {
				...state,
				paymentList: action.payload.paymentList,
			};
		default:
			return state;
	}
};

export default paymentListReducer;