import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import ArrowBackIosRounded from "@material-ui/icons/ArrowBackIosRounded";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import ButtonCommom from "../common/Button";
import { telephoneNumberFormat } from "../utils";
import CloseIcon from "@material-ui/icons/Close";
import AppDownloadRedirectDialog from "../common/AppDownloadRedirectDialog";

const Expired = (props) => {
	const classes = useStyles();
	let navigate = useNavigate();

	useEffect(() => {}, []);

	const [openModal, setOpenModal] = useState(false);

	return (
		<Fragment>
			<div style={{ width: "100vw", display: "flex", justifyContent: "center" }}>
				<div
					style={{
						width: "28vw",
						height: "60vw",
						maxHeight: "700px",
						borderRadius: 50,
						boxShadow: "0px 0px 20px #888888",
						marginTop: "15vh",
					}}>
					<div>
						<CloseIcon
							onClick={() => {
								navigate("/login")
							}}
							style={{
								width: "calc(2vw + 10px)",
								height: "calc(2vw + 10px)",
								cursor: "pointer",
								color: "gray",
								marginTop: "20px",
								marginLeft: "88%",
							}}></CloseIcon>
					</div>
					<div style={{ marginTop: "1vh" }}>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Typography style={{ fontSize: "calc(1.5vw + 5px)" }}>
								<strong className={classes.title}>Seu plano venceu!</strong>
							</Typography>
						</div>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Typography style={{ marginTop: "calc(3vh + 10px)", width: "20vw", textAlign: "center" }}>
								<strong className={classes.message} style={{ fontSize: "calc(1.5vh + 5px)" }}>
									Bora renovar e continuar a usar o PRIT para facilitar sua vida! Volte a fazer sua empresa crescer com
									disponibilidade e gerenciamento da sua agenda e do seu negócio 24 horas por dia, através do PRIT.
								</strong>
							</Typography>
						</div>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Typography style={{ marginTop: "calc(3vh + 10px)", width: "20vw", textAlign: "center" }}>
								<strong className={classes.message} style={{ fontSize: "calc(1.5vh + 5px)" }}>
									Sem custos ocultos ou surpresas desagradáveis. Cancele quando quiser, não temos fidelidade.
								</strong>
							</Typography>
						</div>

						<div style={{ display: "flex", justifyContent: "center", marginTop: "1vw" }}>
							<ButtonCommom
								style={{ textTransform: "none" }}
								text={"Renovar"}
								params={{}}
								onClick={() => {
									setOpenModal(true);
								}}
							/>
						</div>

						<AppDownloadRedirectDialog
							open={openModal}
							setOpen={setOpenModal}
							message={"Baixe agora o Prit para Profissionais em seu celular e renove seu plano!"}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Expired;
