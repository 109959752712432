import Constants from "../../helpers/Constants";

const professionalServiceReducer = (state = {professionalServices: null}, action) => {
	switch (action.type) {
		case Constants.GET_PROFESSIONAL_SERVICES:
			return {
				...state,
				professionalServices: action.payload.professionalServices,
			};
		default:
			return state;
	}
};

export default professionalServiceReducer;