import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
	title: {
		color: "#393762",
		lineHeight: "0.9",
		letterSpacing: "-0.66px",
	},
	container: {
		margin: "auto",
		textAlign: "center",
		width: "50%",
		padding: "10px",
	},
	littlePurple: {
		color: "gray",
	},
	littleBlue: {
		color: "#3689ea",
		lineHeight: "0.9",
		letterSpacing: "-0.66px",
	},
	field: {
		width: "40px",
		fontSize: "300px",
		padding: "5px",
	},
	error: {
		color: "#ff3b30",
		fontSize: "15px",
	},
	icon: {
		cursor: "pointer",
		color: "#3689ea",
		transform: "scale(2)",
	},
	message: {
		color: "rgba(71, 71, 71, 0.3)",
		fontSize: '15px',
		paddingBottom: 10,
	},
}));
