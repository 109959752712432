import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/images/prit.png'; 

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#ffffff',
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    color: "#393762",
    fontWeight: "bold"
  },
  logo: {
    maxWidth: '100px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80px',
    },
  },
}));

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Header(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar className={classes.appBar}>
          <Toolbar style={{minHeight: '80px'}}>
            <img src={logo} alt="Logo" className={classes.logo} /> 
            <Typography variant="h6" className={classes.title}>
              Seja bem-vindo ao Prit!
            </Typography>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </React.Fragment>
  );
}
