import React from "react";
import Login from "../../components/login";
import { useSelector } from "react-redux";

const LoginPage = () => {
	
	return <Login />;
};

export default LoginPage;
