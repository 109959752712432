import Constants from "../../helpers/Constants";

const scheduleReducer = (state = { bookings: null, datedBookings: null }, action) => {
	switch (action.type) {
		case Constants.GET_BOOKINGS:
			return {
				...state,
				bookings: action.payload.bookings,
			};
		case Constants.GET_DATED_BOOKINGS:
			return {
				...state,
				datedBookings: action.payload.datedBookings,
			};
		default:
			return state;
	}
};

export default scheduleReducer;
