import React, { Fragment, useState } from "react";
import { Button, Dialog, Divider, Typography } from "@mui/material";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { convertMonth } from "utils";
import CloseIcon from "@material-ui/icons/Close";
import { Call, Chat, MailOutline, QueryBuilder, WhatsApp } from "@material-ui/icons";
import { moneyMask } from "../../utils";
import { makeStyles } from "@material-ui/core";
import SpinnerLoading from "../spinnerLoading/SpinnerLoading";
import { postCancellationBooking, postRefundBusiness } from "../../../services/paymentService";
import { putBookingTransaction } from "../../../services/bookingTransactionService";

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: "bold",
		color: "gray",
		width: "100%",
		fontSize: "calc(1vw + 10px)",
		margin: "10px 10px",
	},
	close: {
		width: "calc(2vw + 20px)",
		height: "calc(2vw + 20px)",
		cursor: "pointer",
		color: "gray",
	},
	userIcon: {
		color: "lightgray",
		margin: "0 1vw",
		width: "calc(5vw + 20px)",
		height: "calc(5vw + 20px)",
		boxShadow: "0px 0px 15px #888888",
		borderRadius: "100%",
	},
	professionalName: {
		fontWeight: "bold",
		marginTop: "15px",
		color: "gray",
		fontSize: "calc(0.8vw + 5px)",
	},
	professionalIcons: {
		color: "gray",
		backgroundColor: "white",
		borderRadius: "30px",
		boxShadow: "0px 0px 5px #888888",
		margin: ".5vw",
		width: "calc(1vw + 10px)",
		height: "calc(1vw + 10px)",
		padding: "0.5vw",
	},
	subTitle: {
		fontWeight: "bold",
		margin: "1vw",
		color: "gray",
		fontSize: "calc(1vw + 10px)",
		fontWeight: "bold",
	},
	cardContainer: {
		backgroundColor: "#eeeeee",
		borderRadius: "30px",
		margin: "1.5vw",
		boxShadow: "0px 5px 5px #888888",
		display: "flex",
		justifyContent: "center",
		flexGrow: "1",
	},
	cardContent: {
		flexDirection: "column",
		margin: "1vw",
		alignItems: "center",
		display: "flex",
		flexGrow: "1",
		alignContent: "center",
		justifyContent: "space-around",
	},
	checkoutButton: {
		borderRadius: "25px",
		color: "white",
		backgroundColor: "red",
		width: "200px",
		textTransform: "none",
		margin: "20px",
		fontSize: "calc(0.5vw + 10px)",
		padding: "10px",
	},
	statusLabel: {
		color: "white",
		padding: "5px",
		borderRadius: "25px",
		textAlign: "center",
		textTransform: "uppercase",
		fontSize: "calc(0.5vw + 5px)",
	},
}));

const ReservationDetailsDialog = (props) => {
	const classes = useStyles();
	const { business, open, setOpen, booking, bookingTransaction, paymentList } = props;

	const [loading, setLoading] = useState(false);

	return (
		<Fragment>
			<Dialog
				open={open}
				PaperProps={{
					style: { borderRadius: 20 },
				}}>
				<SpinnerLoading loading={loading}></SpinnerLoading>
				<div style={{ display: "flex", margin: "20px 1vw" }}>
					<Typography
						style={{
							fontWeight: "bold",
							color: "gray",
							width: "100%",
							fontSize: "calc(1vw + 10px)",
							margin: "10px 10px",
						}}>
						Detalhes da Reserva
					</Typography>
					<CloseIcon
						className={classes.close}
						onClick={() => {
							setOpen(false);
						}}></CloseIcon>
				</div>
				<div
					style={{
						display: "flex",
					}}>
					<AccountCircleIcon className={classes.userIcon}></AccountCircleIcon>
					<div>
						<Typography style={{ fontWeight: "bold", marginTop: "15px", color: "gray", fontSize: "calc(0.8vw + 5px)" }}>
							{booking?.professional?.name || bookingTransaction?.bookings[0]?.professional?.name}
						</Typography>
						<div>
							<WhatsApp className={classes.professionalIcons}></WhatsApp>
							<Call className={classes.professionalIcons}></Call>
							<MailOutline className={classes.professionalIcons}></MailOutline>
							<Chat className={classes.professionalIcons}></Chat>
						</div>
					</div>
				</div>
				<div>
					<Typography
						style={{
							fontWeight: "bold",
							margin: "1vw",
							color: "gray",
							fontSize: "calc(1vw + 10px)",
							fontWeight: "bold",
						}}>
						Serviços
					</Typography>
					<div className={classes.cardContainer}>
						<div className={classes.cardContent}>
							<Typography
								style={{
									color: "gray",
									textAlign: "center",
									fontWeight: "bold",
									fontSize: "calc(0.5vw + 5px)",
								}}>
								{convertMonth(new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getMonth())}
							</Typography>
							<Typography
								style={{
									color: "gray",
									textAlign: "center",
									fontSize: "calc(0.5vw + 5px)",
								}}>
								{(new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getDate() < 10
									? "0" + new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getDate()
									: new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getDate()) +
									"/" +
									(new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getMonth() + 1 < 10
										? "0" + (new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getMonth() + 1)
										: new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getMonth() + 1)}
							</Typography>
							<Divider flexItem style={{ color: "gray" }}></Divider>
							<Typography
								style={{
									fontWeight: "bold",
									color: "gray",
									fontSize: "calc(0.5vw + 5px)",
									textAlign: "center",
								}}>
								{(new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getHours() < 10
									? "0" + new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getHours()
									: new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getHours()) +
									":" +
									(new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getMinutes() < 10
										? "0" + new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getMinutes()
										: new Date(booking?.startTime || bookingTransaction?.bookings[0]?.startTime).getMinutes())}
							</Typography>
							<Typography
								style={{
									fontWeight: "bold",
									color: "gray",
									fontSize: "calc(0.5vw + 5px)",
									textAlign: "center",
								}}>
								-
							</Typography>
							<Typography
								style={{
									fontWeight: "bold",
									color: "gray",
									fontSize: "calc(0.5vw + 5px)",
									textAlign: "center",
								}}>
								{(new Date(
									booking?.endTime || bookingTransaction?.bookings[bookingTransaction?.bookings?.length - 1]?.endTime
								).getHours() < 10
									? "0" +
									  new Date(
											booking?.endTime ||
												bookingTransaction?.bookings[bookingTransaction?.bookings?.length - 1]?.endTime
									  ).getHours()
									: new Date(
											booking?.endTime ||
												bookingTransaction?.bookings[bookingTransaction?.bookings?.length - 1]?.endTime
									  ).getHours()) +
									":" +
									(new Date(
										booking?.endTime || bookingTransaction?.bookings[bookingTransaction?.bookings?.length - 1]?.endTime
									).getMinutes() < 10
										? "0" +
										  new Date(
												booking?.endTime ||
													bookingTransaction?.bookings[bookingTransaction?.bookings?.length - 1]?.endTime
										  ).getMinutes()
										: new Date(
												booking?.endTime ||
													bookingTransaction?.bookings[bookingTransaction?.bookings?.length - 1]?.endTime
										  ).getMinutes())}
							</Typography>
						</div>
						<Divider
							orientation="vertical"
							flexItem
							style={{
								color: "gray",
							}}></Divider>
						<div
							style={{
								width: "100%",
							}}>
							<div>
								{bookingTransaction?.bookings.map((booking) => {
									return (
										<div
											style={{
												display: "flex",
												marginBottom: "1vw",
											}}>
											<div
												style={{
													margin: "0.5vw",
												}}>
												<Typography
													style={{
														fontSize: "calc(0.5vw + 10px)",
														fontWeight: "bold",
														color: "gray",
													}}>
													{booking?.service?.name}
												</Typography>
												<Typography
													style={{
														fontSize: "calc(0.5vw + 5px)",
														color: "gray",
													}}>
													{"com " + booking?.professional?.name}
												</Typography>
												<div
													style={{
														display: "flex",
														width: "10vw",
													}}>
													<QueryBuilder
														style={{
															width: "calc(1vw + 5px)",
															height: "calc(1vw + 5px)",
															color: "lightgray",
														}}></QueryBuilder>
													<Typography
														style={{
															color: "lightgray",
															fontSize: "calc(0.5vw + 5px)",
														}}>
														{booking?.duration + "m"}
													</Typography>
													<Typography
														style={{
															marginLeft: "10px",
															color: "#3689ea",
															fontSize: "calc(0.5vw + 5px)",
															whiteSpace: "nowrap",
														}}>
														{booking?.pricingType?.name + " " + moneyMask(booking?.price * 100, business.currency)}
													</Typography>
												</div>
											</div>
											<div
												style={{
													alignContent: "end",
													width: "100%",
												}}>
												<Typography
													className={classes.statusLabel}
													style={{
														backgroundColor: "#3689ea",
														margin: "15px 1vw",
													}}>
													{booking?.status == "active" ? "ativo" : "inativo"}
												</Typography>
												<Typography
													className={classes.statusLabel}
													style={{
														backgroundColor: paymentList?.payments ? "green" : "red",
														margin: "15px 1vw",
													}}>
													{bookingTransaction?.paymentStatus == "pending" ? "pendente" : "pago"}
												</Typography>
											</div>
										</div>
									);
								})}
							</div>
							<div
								style={{
									display: "flex",
									marginBottom: "1vw",
								}}>
								<Typography
									style={{
										width: "50%",
										margin: "0.5vw",
										color: "gray",
										fontSize: "calc(1vw + 5px)",
									}}>
									Total
								</Typography>
								<Typography
									style={{
										width: "50%",
										textAlign: "right",
										marginRight: "1.5vw",
										color: "#3689ea",
										fontSize: "calc(1vw + 5px)",
									}}>
									{paymentList ? moneyMask(paymentList?.total_services, business.currency) : "-"}
								</Typography>
							</div>
						</div>
					</div>
					<Typography
						style={{
							fontWeight: "bold",
							margin: "1vw",
							color: "gray",
							fontSize: "calc(0.6vw + 10px)",
						}}>
						Pagamentos
					</Typography>
					<div
						style={{
							display: "flex",
							margin: "10px 30px",
						}}>
						<Typography
							style={{
								width: "50%",
								color: "gray",
								fontSize: "calc(0.5vw + 5px)",
							}}>
							Desconto
						</Typography>
						<Typography
							style={{
								width: "50%",
								textAlign: "right",
								marginRight: "30px",
								color: "gray",
								fontSize: "calc(0.5vw + 5px)",
							}}>
							{paymentList ? moneyMask(paymentList?.total_discount, business.currency) : "-"}
						</Typography>
					</div>
					<div
						style={{
							display: "flex",
							margin: "10px 30px",
						}}>
						<Typography
							style={{
								width: "50%",
								color: "gray",
								fontSize: "calc(0.5vw + 5px)",
							}}>
							Antecipado
						</Typography>
						<Typography
							style={{
								width: "50%",
								textAlign: "right",
								marginRight: "30px",
								color: "gray",
								fontSize: "calc(0.5vw + 5px)",
							}}>
							{paymentList ? moneyMask(paymentList?.total_available, business.currency) : "-"}
						</Typography>
					</div>
					<Divider></Divider>
					<div
						style={{
							display: "flex",
							margin: "10px 30px",
						}}>
						<Typography
							style={{
								width: "50%",
								color: "gray",
								fontSize: "calc(0.5vw + 5px)",
							}}>
							Lançado
						</Typography>
						<Typography
							style={{
								width: "50%",
								textAlign: "right",
								marginRight: "30px",
								color: "gray",
								fontSize: "calc(0.5vw + 5px)",
							}}>
							{paymentList ? moneyMask(paymentList?.balance_to_pay, business.currency) : "-"}
						</Typography>
					</div>
					<Typography
						style={{
							fontWeight: "bold",
							margin: "1vw",
							color: "gray",
							fontSize: "calc(0.6vw + 10px)",
						}}>
						{bookingTransaction?.internalNote || "Pergunta não definida"}
					</Typography>
					<Typography
						style={{
							marginLeft: "30px",
							marginTop: "10px",
							color: "gray",
							fontSize: "calc(0.5vw + 5px)",
						}}>
						{bookingTransaction?.client?.observation || "Sem observações"}
					</Typography>
				</div>
				<div
					style={{
						justifyContent: "center",
						width: "100%",
						display: "flex",
						marginTop: "30px",
					}}>
					<Button
						onClick={async () => {
							setLoading(true);
							await postRefundBusiness({
								recipient_id: business.id,
								booking_transaction_id: bookingTransaction.id,
								date_booking_created: bookingTransaction.createdAt,
								services_id: [bookingTransaction.bookings[0].service.id],
							});
							// await putBookingTransaction({
							// 	id: bookingTransaction.id,
							// 	paymentStatus: "cancelled",
							// 	cancellationReason: "business",
							// });
							setLoading(false);
							setOpen(false);
							alert("Cancelamento realizado com sucesso!");
							window.location.reload();
						}}
						style={{
							borderRadius: "25px",
							color: "white",
							backgroundColor: "red",
							width: "200px",
							textTransform: "none",
							margin: "20px",
							fontSize: "calc(0.5vw + 10px)",
							padding: "10px",
						}}>
						Cancelar
					</Button>
					{/* <Button
					style={{
						borderRadius: "25px",
						color: "white",
						backgroundColor: "red",
						width: "200px",
						textTransform: "none",
						margin: "20px",
						fontSize: "calc(0.5vw + 10px)",
						padding: "10px",
					}}>
					Checkout
				</Button> */}
				</div>
			</Dialog>
		</Fragment>
	);
};
export default ReservationDetailsDialog;
