import Constants from "../../helpers/Constants";

const clientReducer = (state = {clients: null}, action) => {
	switch (action.type) {
		case Constants.GET_CLIENTS:
			return {
				...state,
				clients: action.payload.clients,
			};
		default:
			return state;
	}
};

export default clientReducer;