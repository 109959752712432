import Constants from "../../helpers/Constants";

const subscriptionCurrentReducer = (state = { subscription_current: null }, action) => {
	switch (action.type) {
		case Constants.SET_SUBSCRIPTION_CURRENT:
			return {
				...state,
				subscription_current: action.payload.subscription_current,
			};
		default:
			return state;
	}
};

export default subscriptionCurrentReducer;
