import { signInWithCustomToken } from "firebase/auth";
import apiTwilio from "./apiTwilio";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export const sendMsgWavy = (celphone) => {
	return apiTwilio.apiTwilio.post('user/auth/wavy', {
		phone: celphone,
	});
};

export const sendMessageWavy = (celphone, codeWhatsApp) => {
	return apiTwilio.apiTwilio.post('user/auth/wavy/verify', {
		phone: celphone,
		code: codeWhatsApp,
	});
};

export const sendTokenFirebase = (auth, token) => {
	return  signInWithCustomToken(auth, token);
};