import Constants from "../../helpers/Constants";

const sectorReducer = (state = {sector: null}, action) => {
	switch (action.type) {
		case Constants.GET_SECTOR:
			return {
				...state,
				sector: action.payload.sector,
			};
		default:
			return state;
	}
};

export default sectorReducer;