import { createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import middlewares from "../store/middlewares";
import reducers from "../store/reducers";

const persistConfig = {
	key: "root",
	storage,
	blacklist: ["auth", "schedule", "login"],
};

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = createStore(persistedReducer, middlewares);
export const persistor = persistStore(store);

export default store;
