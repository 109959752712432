import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
	eachFade: {
		textAlign: "center",
		overflow: "hidden",
		width: "100%",
		height: "100%",
	},
	container: {
		marginTop: "17%",
		width: "100%",
		minWidth: "100%",
		maxWidth: "50%",
		justifyContent:"center"
	},
}));
