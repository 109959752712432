import React, { useState } from 'react';
import { Typography, Container } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

import { useLocation, useNavigate } from 'react-router';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Header from '../header';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import paymentService from '../../services/paymentService';
import ButtonCommom from '../common/Button';
import { FormControlLabel, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';


const PaymentPix = () => {
  const location = useLocation();
	const navigate = useNavigate();

  const idPlan  = location?.state;

  const [cpf, setCpf] = useState('');
  const [error, setError] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [isCnpjActive, setIsCnpjActive] = useState(false);

  const user = useSelector(state => state.me.me);
  const businessInfor = useSelector(state => state.business.business);
  const [open, setOpen] = useState(false);
  const [qrCode, setQrCode] = useState('');

  const me = user;
  const business = businessInfor;

  const handleCpfChange = (event) => {
    const inputValue = event.target.value;
    const cleanedCpf = inputValue.replace(/\D/g, '');
  
    if (cleanedCpf.length === 11) {
      const formattedCpf = `${cleanedCpf.slice(0, 3)}.${cleanedCpf.slice(3, 6)}.${cleanedCpf.slice(6, 9)}-${cleanedCpf.slice(9)}`;
      setCpf(formattedCpf);
    } else {
      console.error('CPF inválido. Deve conter 11 dígitos.');
    }
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    setCpf(value);
  };
  

  const handleCnpjChange = (event) => {
    let value = event.target.value;
    const cleanedValue = value.replace(/\D/g, "");
    setCnpj(cleanedValue);
  
    value = value.replace(/^(\d{2})(\d)/, "$1.$2"); 
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); 
    value = value.replace(/\.(\d{3})(\d)/, ".$1/$2"); 
    value = value.replace(/(\d{4})(\d)/, "$1-$2"); 
    setCnpj(value); 
  };

  const handleSwitchChange = (event) => {
    setIsCnpjActive(event.target.checked);
  };

  
  const handlePayment = () => {
    const body = {};
    body.payment_subscription_type = 'pix';
    body.recipient_id = business?.id;
    body.doc = isCnpjActive ? cnpj.replace(/\D/g, '') : cpf.replace(/\D/g, '');   
    body.doc_type = isCnpjActive ? 'cnpj' : 'cpf';
    body.email = me && !isEmpty(me.email) ? me.email : 'appprit@gmail.com';
    body.name = me && !isEmpty(me.name) ? me.name : 'Prit';
    body.phone = me && !isEmpty(me.phone) ? me.phone : '+5516982099585';
    body.neighborhood = business?.address.neighborhood ? business?.address.neighborhood : 'Jardim Botânico'; 
    body.city = business?.address.city ? business?.address.city : 'Ribeirão Preto';
    body.state =  business?.address.state ? business?.address.state :'SP';
    body.street = business?.address.street ? business?.address.street : 'Av. Professor João Fiúsa';
    body.street_number = business?.address.number ? business?.address.number : 1901;
    body.zip_code = business?.address.postalCode ? business?.address.postalCode : '14024250';
    body.plan_id = idPlan;

    paymentService.postPaySubscription(body)
      .then((response) => {
        navigate("/menu/qrcode/pix", { state: { qrcode: response.data.pixQrCode } });
      })
      .catch((error) => {
        let errorMessage = error.message;
  
        if (error.response && error.response.data) {
          errorMessage = error.response.data.message || error.response.data;
          setError(errorMessage)
        }
      });
  };

  const label = { inputProps: { 'aria-label': 'cnpjSwitch' } };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '210px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: '12px', 
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'auto', 
    '&:focus': {
      outline: 'none',
    },
  };

  return (
    <Container maxWidth="sm" style={{ 
      background: '#fff',
      borderRadius: 3,
      border: 0,
      color: 'white',
      minWidth: '100vw',
      minHeight: '100vh',
      padding: '0 30px',
      textAlign: "center" ,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    }}>
      <Header />
      <Typography variant="h6" style={{ color: "#585858", fontWeight: 'bold', fontSize: '25px', padding: "15% 0px 5% 0px" }}>
        Pagamento por PIX
      </Typography>
      <Typography variant="subtitle2" style={{ color: "#585858", fontSize: '16px', margin: "0% 0px 8% 0px" }}>
      Para que possamos gerar o PIX, é necessário que tenhamos seu {isCnpjActive ? 'CNPJ' : 'CPF'}.
      </Typography>

      <Grid container justify="center" alignItems="center" style={{justifyContent: 'center'}}>
        <Grid item xs={12} sm={6} md={4}>
          <Box display="flex" justifyContent="center">
            <TextField
              InputProps={{style: { borderRadius: 50 }}}
              multiline
              maxRows={4}
              value={isCnpjActive ? cnpj : cpf}
              onChange={isCnpjActive ? handleCnpjChange : handleCpfChange}
              label={`Insira o seu ${isCnpjActive ? 'CNPJ' : 'CPF'}`}
              fullWidth
              inputProps={{ maxLength: isCnpjActive ? 18 : 14 }}
            />
        </Box>
        </Grid>
      </Grid>

      <Grid container justify="center" alignItems="center" style={{justifyContent: 'flex-start', marginTop: '3%'}}>
        <FormControlLabel control={<Switch {...label} checked={isCnpjActive} onChange={handleSwitchChange} />} style={{color: 'grey'}} label="Inserir CNPJ" />
      </Grid>

      <ButtonCommom
              text={isCnpjActive ? 'SEGUIR COM  CNPJ' : 'SEGUIR COM CPF'}
              onClick={handlePayment}
              style={{
                marginTop:'10%',
                width: window.innerWidth > 600 ? "80%" : "100%",
                fontSize: window.innerWidth > 600 ? "1em" : "2em",
                height: '45px',
              }}
      />

      {error && (
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert variant="outlined" severity="error">
            Ocorreu um erro: {error}
          </Alert>
        </Stack>
      )}
      
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '50%' }}>
          <Typography id="parent-modal-title" style={{ textAlign: 'center', color: "#393762" }}>
            Pagamento PIX
          </Typography>
          <p id="parent-modal-description" style={{ textAlign: 'center' }}>
            {qrCode}
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            style={{
              backgroundColor: '#0074D9',
              color: 'white',
              borderRadius: '10px',
              padding: '10px 20px',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={copyToClipboard}
          >
            Copiar QR Code
          </button>
        </div>
        </Box>
      </Modal> */}
    </Container>
  );
}

export default PaymentPix;
