import Constants from "../../helpers/Constants";

const bookingTransactionReducer = (state = {bookingTransaction: null}, action) => {
	switch (action.type) {
		case Constants.GET_BOOKING_TRANSACTION:
			return {
				...state,
				bookingTransaction: action.payload.bookingTransaction,
			};
		default:
			return state;
	}
};

export default bookingTransactionReducer;