import React, { useEffect, useState } from "react";
import { Typography, Container, Grid } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import Box from '@material-ui/core/Box';

import CreditsCard from "assets/images/credit-card.png";
import { useLocation, useNavigate } from 'react-router';
import Cards from 'react-credit-cards';
import { Button, Modal } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import 'react-credit-cards/es/styles-compiled.css';
import Header from "../header";
import { useSelector } from "react-redux";
import paymentService from "../../services/paymentService";
import ButtonCommom from "../common/Button";

const PaymentCredit = () => {
  const location = useLocation();
  const idPlan  = location?.state?.id;
  const navigate = useNavigate();

  const user = useSelector(state => state.me.me);
  const businessInfor = useSelector(state => state.business.business);

  const [cvc, setCvc] = useState('');
  const [cpf, setCpf] = useState('');
  const [expiry, setExpiry] = useState('');
  const [focused, setFocused ] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const handleClose = () => {
    if(error) {
      setOpen(false);
      setError(false)
    } else { 
      navigate("/menu")
    }
    setError(false)
    setOpen(false);
  };

  const isCardAndPlanUpdate = location?.state.isCardAndPlanUpdate;
  const cardUpdate = location?.state.cardUpdate;

  const me = user;
  const business = businessInfor;

  const state = {
    initialValues: isCardAndPlanUpdate ? {
       recipient_id: business?.id,
      card: {
        card_holder_name: name,
        card_expiration_date: expiry.replace(/\D/g, ''),
        card_number: number,
        card_cvv: cvc,
      },
      prit_plan_id: idPlan,
    } : {
      recipient_id: business?.id,
      card: {
        card_holder_name: name,
        card_expiration_date: expiry.replace(/\D/g, ''),
        card_number: number,
        card_cvv: cvc,
      },
      client: {
        name: me ? me.name : '',
        email: me ? me.email : '',
        document_number: cpf.replace(/\D/g, ''),
        phone: { ddd: me.phone.slice(3, 5), number: me.phone.slice(5, 14) },
        address: {
          street: business?.address.street ? business?.address.street : 'Jardim Botânico',
          complementary: business?.address.unit,
          street_number: business?.address.number,
          neighborhood: business?.address.neighborhood ? business?.address.neighborhood  : 'Jardim Botânico',
          city: business?.address.city ? business?.address.city : 'Ribeirão Preto',
          state: business?.address.state,
          zipcode: business?.address.postalCode ? business?.address.postalCode : '14024250',
          country: business?.address.country,
        },

      },
      doc_type: 'cpf',
      prit_plan_id: idPlan,
    }
  };


  const fetchPaymentRecurrenceSubscription = (data) => {
    if(isCardAndPlanUpdate || cardUpdate){
      let dataCard = cardUpdate ? {
        recipient_id: business?.id,
        card: {
          card_holder_name: name,
          card_expiration_date: expiry.replace(/\D/g, ''),
          card_number: number,
          card_cvv: cvc,
        }
      } : data;

      setIsLoading(true)
      paymentService.putPaySubscriptionCredit(dataCard)
        .then(response => {
          setIsLoading(false)
            setOpen(true)
            if (cardUpdate) {
              setMessage('Alteração feita')
            } else {
              setMessage(response.data.message)
            }
        })
        .catch(error => {
          let errorMessage = error.message;
          if (error.response && error.response.data) {
            errorMessage = error.response.data.message || error.response.data;
            setOpen(true)
            setMessage(errorMessage)
          }
        });
    } else{
    setIsLoading(true)
    paymentService.postPaySubscriptionCredit(data)
      .then(response => {
        setIsLoading(false)
          setOpen(true)
          setMessage(response.data.message)
      })
      .catch(error => {
        let errorMessage = error.message;
  
        if (error.response && error.response.data) {
          errorMessage = error.response.data.message || error.response.data;
          setOpen(true);
          setError(true);
          setMessage(errorMessage)
        }
      });
    }
  };


  const handleInputFocus = (e) => {
    setFocused(e.target.name);
  }
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    let formattedValue = value;

    switch (name) {
      case 'number':
        setNumber(formattedValue);
        break;
      case 'name':
        setName(formattedValue);
        break;
      case 'expiry':
        formattedValue = formattedValue.replace(/[^0-9]/g, ''); 
        if (formattedValue.length > 2) {
          formattedValue = formattedValue.slice(0, 2) + '/' + formattedValue.slice(2);
        }
        setExpiry(formattedValue.slice(0, 5)); 
        break;
      case 'cvc':
        setCvc(formattedValue);
        break;
        case 'cpf':
        formattedValue = formattedValue.replace(/\D/g, ""); 
        formattedValue = formattedValue.replace(/(\d{3})(\d)/, "$1.$2"); 
        formattedValue = formattedValue.replace(/(\d{3})(\d)/, "$1.$2"); 
        formattedValue = formattedValue.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); 
        setCpf(formattedValue);
        break;
        default:
        break;
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '210px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: '12px', 
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'auto', 
    '&:focus': {
      outline: 'none',
    },
  };
  
    return (
      <Container maxWidth="sm" style={{ 
        background: '#fff',
        borderRadius: 3,
        border: 0,
        color: 'white',
        minWidth: '100vw',
        minHeight: '100vh',
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      }}>
        <Header />
        <Typography variant="h6" align="center" color="textSecondary" gutterBottom style={{padding: '10% 0 0% 0', color: '#393762'}}>
          Informe os dados do seu cartão de crédito
        </Typography>
        <Typography variant="subtitle2" align="center" color="textSecondary" component="p">
          Nesta opção, o pagamento da mensalidade será feito de forma recorrente.
        </Typography>
        <Grid container display="flex" justifyContent="center" alignItems="center">
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={CreditsCard} alt="Cartão de crédito" style={{width:'20%', margin: '5% 0 5% 0'}}/>
          </Grid>
          <Grid item xs={12} >
            <Cards
              cvc= {cvc}
              expiry={expiry}
              focused={focused}
              name={name}
              number={number}
            />
            <form noValidate autoComplete="off">
              <TextField 
                InputProps={{style: { borderRadius: 50}}}
                error={number === ''}
                fullWidth margin="normal" 
                label="Número do cartão" 
                variant="outlined" 
                type="number" 
                name="number" 
                onChange={handleInputChange} 
                onFocus={handleInputFocus}
              />
              <TextField 
                InputProps={{style: { borderRadius: 50}}} 
                error={name === ''}
                fullWidth margin="normal" 
                label="Nome" 
                variant="outlined" 
                type="text" 
                name="name" 
                onChange={handleInputChange} 
                onFocus={handleInputFocus} 
              />
              <TextField
                InputProps={{style: { borderRadius: 50}}}
                error={expiry === ''}
                fullWidth
                margin="normal"
                label="Data de validade"
                variant="outlined"
                type="text"
                name="expiry"
                value={expiry}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />              
              <TextField 
                InputProps={{style: { borderRadius: 50}}} 
                error={cvc === ''}
                fullWidth margin="normal" 
                label="CVC" 
                variant="outlined" 
                type="password" 
                name="cvc" 
                onChange={handleInputChange} 
                onFocus={handleInputFocus} 
                inputProps={{ maxLength: 3 }}
              />
              <TextField 
                InputProps={{style: { borderRadius: 50}}} 
                error={cpf === ''}
                fullWidth margin="normal" 
                label="CPF" 
                variant="outlined" 
                type="text" 
                name="cpf"
                value={cpf}
                onChange={handleInputChange} 
                onFocus={handleInputFocus} 
                inputProps={{ maxLength: 14 }}
              />

              <ButtonCommom
                text={cardUpdate ? 'TROCAR CARTÂO' :'ASSINAR PLANO'}
                onClick={() => fetchPaymentRecurrenceSubscription(state.initialValues)}
                style={{
                  marginTop:'10%',
                  width: window.innerWidth > 600 ? "80%" : "100%",
                  fontSize: window.innerWidth > 600 ? "1em" : "2em",
                  height: '45px',
                }}
                disable={number.length === 0 || name.length === 0 || expiry.length === 0 || cvc.length === 0 || cpf.length === 0} 
              />
            </form>
          </Grid>
        </Grid>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '50%' }}>
          <Typography id="parent-modal-title" style={{ textAlign: 'center', color: "#393762" }}>
            Assinatura OK
          </Typography>
          <p id="parent-modal-description" style={{ textAlign: 'center' }}>
            {message}
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            style={{
              backgroundColor: '#0074D9',
              color: 'white',
              borderRadius: '10px',
              padding: '10px 20px',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={()=> handleClose()}
          >
           Ok
          </button>
        </div>
        </Box>
      </Modal>
      </Container>
    );
};
export default PaymentCredit;

