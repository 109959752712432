import Constants from "../../helpers/Constants";

const subscriptionDataReducer = (state = { subscription_data: null }, action) => {
	switch (action.type) {
		case Constants.SET_SUBSCRIPTION_DATA:
			return {
				...state,
				subscription_data: action.payload.subscription_data,
			};
		default:
			return state;
	}
};

export default subscriptionDataReducer;
