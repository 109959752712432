import meService from "../../services/meService";
import Constants from "../../helpers/Constants";

const getMe = (config) => (dispatch) => {
	meService.getMe(config).then(({ data }) => {
	  dispatch({ type: Constants.SET_ME, payload: { me: data } });
	});
  };
  
  export default { getMe };
