import React from 'react';
import { Button, Typography, Container } from "@material-ui/core";

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import CreditsCard from "assets/images/credit-card.png";
import { useLocation, useNavigate } from 'react-router';
import Header from '../header';
import { useSelector } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

const CurrentPlan = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const card = location?.state?.card;
  const cancel = location?.state?.cancel;

  const subscriptionCurrent = useSelector(state => state.subscription_current.subscription_current);

  let expirationDate = new Date(subscriptionCurrent.expiration);
  let formattedDate = format(expirationDate, 'dd MMMM yyyy', { locale: ptBR });

  const style = {
    p: 0,
    width: '100%',
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
    margin: "30% 0px 5% 0px"  
  };
  
  const handleSubscriptionChange = () => {
    navigate("/menu", { state: { isCardAndPlanUpdate: true, view: true } });
  };

  const cardCreditChange = () => {
    navigate("/menu/method/credit", { state: { cardUpdate: true } });
  };

  const handleDetailsCard = () => {
    navigate("/menu/details");
  };

  const handleCancel = () => {
    navigate("/menu/cancelar");
  };

  return (
        <Container maxWidth="sm" style={{ 
        background: '#fff',
        borderRadius: 3,
        border: 0,
        color: 'white',
        minWidth: '100vw',
        minHeight: '100vh',
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      }}>
        <Header />
        {!card?.is_recurrence ? (
          <>
            <Typography variant="subtitle2" style={{ color: "#585858", fontSize: '16px', padding: "30% 0px 5% 0px" }}>
            {cancel ? 'Você cancelou seu plano' : `Plano atual: ${subscriptionCurrent.current_plan}` }
            </Typography>

            <Typography variant="subtitle2" style={{ color: "#585858", fontSize: '16px', padding: "0% 0px 10% 0px" }}>
              {cancel ? `Seu plano estará ativo até o dia ${formattedDate}` : `Seu plano expira em  ${formattedDate}` }
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: "20px", backgroundColor:"#3688EB", borderRadius:"20px",  fontWeight:'bold', height:'5vh' }}
              onClick={()=> navigate("/menu", { state: {view: true }})}
          >
              {cancel? 'Contratar Plano' : 'Outros Planos'}
            </Button>
          </>
        ) : (
          <>
            <List sx={style} aria-label="mailbox folders">
              <ListItem>
                <Typography variant="subtitle2" style={{ color: "#585858", fontSize: '16px'}}> 
                  Plano atual: <span style={{ color: "#393662", cursor: "pointer", fontWeight: "bold" }}> {subscriptionCurrent.current_plan} </span> 
                  <Typography>Sua próxima data de cobrança é em <span style={{ color: "#393662", cursor: "pointer", fontWeight: "bold" }}> {formattedDate} </span> </Typography>
                </Typography>    
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <Typography variant="subtitle2" style={{ color: "#585858", fontSize: '16px'}}> 
                  Cartão de crédito terminado em •••• {subscriptionCurrent.card_last_digits} 
                  <Typography onClick={cardCreditChange}><span style={{ color: "blue", cursor: "pointer", fontWeight: "bold", textDecoration: 'underline' }}> Editar </span> </Typography>
                </Typography>
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <Typography onClick={handleDetailsCard} variant="subtitle2" style={{ color: "#585858", fontSize: '16px', textDecoration: 'underline'}}> Detalhes da cobrança </Typography>
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <Typography onClick={handleSubscriptionChange} variant="subtitle2" style={{ color: "#585858", fontSize: '16px', textDecoration: 'underline'}}> Alterar plano de assinatura </Typography>
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <Typography onClick={handleCancel} variant="subtitle2" style={{ color: "#585858", fontSize: '16px', textDecoration: 'underline'}}> Cancelar assinatura </Typography>
              </ListItem>
            </List>
          </>
        )}
      
    </Container>
  );
}
export default CurrentPlan;
