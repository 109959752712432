import subscriptionData from "../../services/subscriptionDataService";
import Constants from "../../helpers/Constants";

const getSubscriptionData = (config) => (dispatch) => {
	subscriptionData.getSubscriptionData(config).then(({ data }) => {
		dispatch({ type: Constants.SET_SUBSCRIPTION_DATA, payload: { subscription_data: data } });
	});
};

const getSubscriptionCurrent = (config) => (dispatch) => {
	subscriptionData.getSubscriptionCurrent(config).then(({ data }) => {
		dispatch({ type: Constants.SET_SUBSCRIPTION_CURRENT, payload: { subscription_current: data } });
	});
}

export default { getSubscriptionData, getSubscriptionCurrent };
