import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button, Container, Dialog, Divider, TextField, Typography } from "@mui/material";
import useStyles from "./styles";
import ButtonCommom from "../common/Button";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { auth } from "services/firebase";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";
import { fireBaseErrors, trimTelephoneNumber } from "../../utils";
import { useMixpanel } from "react-mixpanel-browser";
import Header from "../header";
import { sendMessageWavy, sendMsgWavy, sendTokenFirebase } from "../../services/loginService";

const Code = (props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const mixpanel = useMixpanel();

	const telephoneNumber = location?.state?.telephoneNumber;
	const ddi = location?.state?.ddi;

	const [timeCount, setTimeCount] = useState(60);

	const [error] = useState(null);

	const [codeDigits, setCodeDigits] = useState(["", "", "", "", "", ""]);

	const [appVerifier, setAppVerifier] = useState(null);

	const [user, setUser] = useState(null);

	const [openModal, setOpenModal] = useState(false);

	const [modalMessage, setModalMessage] = useState("");

	const [loading, setLoading] = useState(true);

	const sendCode = () => {
		if (telephoneNumber) {
			sendMsgWavy("+" + ddi + trimTelephoneNumber(telephoneNumber))
				.then((confirmationResult) => {
					window.confirmationResult = confirmationResult;
				})
				.catch((error) => {
					setModalMessage(error.message);
					setOpenModal(true);
				});
		}
	};

	const verifyCode = () => {
		if (window.confirmationResult && codeDigits.join("").length === 6) {
			sendMessageWavy("+" + ddi + trimTelephoneNumber(telephoneNumber), codeDigits.join(""))
				.then((result) => {
					sendTokenFirebase(auth, result.data.message).then((res) => {
						setUser(result.data.message);
						localStorage.setItem("logged", "true");
						mixpanel.identify(user?.phoneNumber ? user?.phoneNumber : "", {
							my_custom_prop: "foo",
						});
						mixpanel.track("Login - WB", {
							my_custom_prop: "foo",
						});
					});
				})
				.catch((error) => {
					setModalMessage(fireBaseErrors(error.code));
					for (let i = 0; i < 6; i++) {
						document.getElementById(`codeDigit${i}`).value = "";
					}
					setCodeDigits(["", "", "", "", "", ""]);
					document.getElementById("codeDigit0").focus();
					setOpenModal(true);
				});
		}
	};

	useEffect(() => {
		if (timeCount > 0) {
			setTimeout(() => {
				setTimeCount(timeCount - 1);
			}, 1000);
		}
	}, [timeCount]);

	useEffect(() => {
		setAppVerifier(
			new RecaptchaVerifier(
				"recaptcha",
				{
					size: "invisible",
					callback: (response) => {},
				},
				auth
			)
		);
		setTimeout(() => {
			setLoading(false);
			document.getElementById("codeDigit0")?.focus();
		}, 2000);
	}, []);

	useEffect(() => {
		if (appVerifier) {
			setLoading(false);
		}
	}, [appVerifier]);

	useEffect(() => {
		if (!loading) {
			sendCode();
		}
	}, [loading]);

	useEffect(() => {
		if (user) {
			localStorage.setItem("logged", "true");
			navigate("/menu", { state: { user: user } });
		}
	}, [user]);

	useEffect(() => {
		if (localStorage.getItem("logged") === "true") {
			navigate("/");
		}
	}, []);

	useEffect(() => {
		if (!telephoneNumber) {
			navigate("/");
		}
	}, [telephoneNumber]);

	const handleKeyPress = (e, index) => {
		const updatedCodeArray = [...codeDigits];
		if (e.key === "Delete" || e.key === "Backspace") {
			updatedCodeArray[index] = "";
			setCodeDigits(updatedCodeArray);
			if (index > 0) document.getElementById(`codeDigit${index - 1}`).focus();
		} else if (/^\d$/.test(e.key)) {
			updatedCodeArray[index] = e.key;
			setCodeDigits(updatedCodeArray);
			if (index < 5) document.getElementById(`codeDigit${index + 1}`).focus();
		}
	};

	const handleAutoFill = (e) => {
		const code = e.target.value;
		if (code.length === 6 && /^\d{6}$/.test(code)) {
			for (let i = 0; i < 6; i++) {
				document.getElementById(`codeDigit${i}`).value = code[i];
				document.getElementById(`codeDigit${i}`).dispatchEvent(new Event('change', { bubbles: true }));
			}
		}
	};

	return (
		<Container
			maxWidth="sm"
			style={{
				background: '#ffffff',
				borderRadius: 3,
				border: 0,
				color: 'white',
				minWidth: '100vw',
				minHeight: '100vh',
				padding: '0 30px',
				boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
			}}>
			<Fragment>
				<Header />
				<SpinnerLoading loading={loading}></SpinnerLoading>
				<Dialog
					open={openModal}
					PaperProps={{
						style: { borderRadius: 20, maxWidth: 400 },
					}}>
					<Typography style={{ margin: "30px", textAlign: "center", fontSize: "calc(0.5vw + 10px)" }}>
						<strong className={classes.littlePurple}>{modalMessage}</strong>
					</Typography>
					<Divider></Divider>
					<Button
						className={classes.littleBlue}
						style={{ padding: "15px", fontSize: "calc(0.5vw + 10px)" }}
						onClick={() => setOpenModal(false)}>
						OK
					</Button>
				</Dialog>
				<div style={{ marginTop: "10vh" }}>
					<div style={{ margin: " 15vw 0 2vw" }}>
						<Typography style={{ fontSize: "18px" }}>
							<strong className={classes.title}>Informe o código de confirmação</strong>
						</Typography>
					</div>

					<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
						<Typography
							className={classes.title}
							style={{ marginTop: "5%", alignSelf: "center", fontSize: "15px" }}>
							{"Seu número: " + telephoneNumber}
						</Typography>

						<Button
							style={{
								color: "#3689ea",
								textTransform: "none",
								fontSize: "12px",
								width: "5vw",
								alignSelf: "center",
							}}
							onClick={() => {
								navigate("/login");
							}}>
							Alterar
						</Button>

						<input
							type="text"
							style={{ position: "absolute", opacity: 0, pointerEvents: "none" }}
							autoComplete="one-time-code"
							onChange={handleAutoFill}
						/>

						<div style={{ marginTop: "1vw", alignSelf: "center", padding: "0.5vw" }}>
							{[...Array(6)].map((_, index) => (
								<TextField
									key={index}
									id={`codeDigit${index}`}
									style={{ padding: "0.5vw 0.5vw", alignSelf: "center", width: "8vw" }}
									className={classes.field}
									inputProps={{
										style: {
											fontSize: "20px",
											margin: 0,
											padding: "1vw 0.5vw",
											textAlign: "center",
										},
										maxLength: 1,
										pattern: "[0-9]*",
										inputMode: "numeric"
									}}
									value={codeDigits[index]}
									onKeyDown={(e) => handleKeyPress(e, index)}
								/>
							))}
						</div>

						<Typography style={{ marginTop: "3vh", alignSelf: "center", fontSize: "12px", textAlign: 'center', width: '50%' }}>
							<strong className={classes.littlePurple}>Não recebeu o código? Aguarde 60s que aparecerá a opção para reenviar</strong>
						</Typography>

						<Typography style={{ alignSelf: "center" }}>
							<Button
								style={{
									color: timeCount === 0 ? "#3689ea" : "gray",
									textTransform: "none",
									fontSize: "calc(0.5vw + 10px)",
								}}
								onClick={() => {
									if (timeCount === 0) {
										sendCode();
										setTimeCount(60);
									}
								}}>
								{timeCount === 0 ? "Re-enviar" : "Re-enviar em " + timeCount + "s"}
							</Button>
						</Typography>

						{error && (
							<Typography>
								<h1 className={classes.error}>O número de telefone inserido não é válido.</h1>
								<h1 className={classes.error}>Por favor, tente novamente</h1>
							</Typography>
						)}
						<div style={{ display: "flex", justifyContent: "center", margin: "1vw 0" }}>
							<ButtonCommom
								id="recaptcha"
								toData={user ? true : false}
								params={{ user: user, telephoneNumber: null }}
								onClick={verifyCode}
								style={{
									width: window.innerWidth > 600 ? "80%" : "100%",
									fontSize: window.innerWidth > 600 ? "1em" : "2em",
									height: '45px',
								}}
							/>
						</div>
					</div>
				</div>
			</Fragment>
		</Container>
	);
};

export default Code;
