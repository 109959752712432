import React, { useState } from 'react';
import { Typography, Container } from "@material-ui/core";
import { useLocation } from 'react-router';
import Header from '../header';
import useStyles, { BillWrapper, BillToken, Icon } from "./styles";
import { MdContentCopy } from "react-icons/md";
import ButtonCommom from '../common/Button';


const PaymentPixQrCode = () => {
  const location = useLocation();
  const classes = useStyles();

  const [copyPix, setCopyPix] = useState(false);

  const pixCode = location?.state?.qrcode;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(pixCode);
        setCopyPix(true);
      } catch (err) {
        setCopyPix(false);
      }
  };

  return (
    <Container maxWidth="sm" style={{ 
      background: '#fff',
      borderRadius: 3,
      border: 0,
      color: 'white',
      minWidth: '100vw',
      minHeight: '100vh',
      padding: '0 30px',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    }}>
      <Header />

      <Typography variant="subtitle2" style={{ color: "#585858", fontSize: '16px', padding: "15% 0px 5% 0px" }}>
       1. Copie o código abaixo</Typography>
       <Typography variant="subtitle2" style={{ color: "#585858", fontSize: '16px', margin: "0% 0px 8% 0px" }}>
        2. Pague via PIX Copia e Cola em qualquer aplicativo habilitado</Typography>

        <div className={classes.BillWrapper}>
          < div className={classes.BillToken} numberOfLines={1}>
            {pixCode}
          </div>
          <MdContentCopy style={{color: 'black'}} onClick={() => copyToClipboard()} />
            {/* <divWrapper
              bundle="material"
              name="content-copy"
              size={23}
              color="#3688EB"
            /> */}
        </div>
        <Typography variant="subtitle2"
          style={{  
            fontSize: '15px',
            fontWeight: 'bold',
            textAlign: 'center',
            color: 'grey',
            marginTop: '3%',
          }}
        >
         Você tem até 10 minutos para fazer o pagamento.
        </Typography>

        <ButtonCommom
              text={copyPix ? 'PIX copiado' : 'Copiar código PIX'}
              onClick={copyToClipboard}
              style={{
                marginTop:'10%',
                width: window.innerWidth > 600 ? "80%" : "100%",
                fontSize: window.innerWidth > 600 ? "1em" : "2em",
                height: '45px',
              }}
          />
    </Container>
  );
}

export default PaymentPixQrCode;
