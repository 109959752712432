import { initializeApp } from "firebase/app";
//import Firebase from "firebase-app";
import "firebase/auth";
import { getAuth } from "firebase/auth";

const prodConfig = {
	apiKey: "AIzaSyALuw6dyIzNThmrj2P93TpR9lnD_ou-6Xg",
	authDomain: "prit-app.firebaseapp.com",
	databaseURL: "https://prit-app.firebaseio.com",
	projectId: "prit-app",
	storageBucket: "prit-app.appspot.com",
	messagingSenderId: "964307259443",
	appId: "1:964307259443:web:fa7b39292c37ead0b55346",
	measurementId: "G-YEMMDF88WG",
};

const config = prodConfig;

export const app = initializeApp(config);

export const auth = getAuth(app);
