import React from "react";
import { Button, Dialog, Divider, Typography } from "@mui/material";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { convertMonth } from "utils";
import CloseIcon from "@material-ui/icons/Close";
import { Call, Chat, MailOutline, QueryBuilder, WhatsApp } from "@material-ui/icons";
import { moneyMask } from "../../utils";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: "bold",
		color: "gray",
		width: "100%",
		fontSize: "calc(1vw + 10px)",
		margin: "10px 10px",
	},
	close: {
		width: "calc(2vw + 20px)",
		height: "calc(2vw + 20px)",
		cursor: "pointer",
		color: "gray",
	},
	userIcon: {
		color: "lightgray",
		margin: "0 1vw",
		width: "calc(5vw + 20px)",
		height: "calc(5vw + 20px)",
		boxShadow: "0px 0px 15px #888888",
		borderRadius: "100%",
	},
	professionalName: {
		fontWeight: "bold",
		marginTop: "15px",
		color: "gray",
		fontSize: "calc(0.8vw + 5px)",
	},
	professionalIcons: {
		color: "gray",
		backgroundColor: "white",
		borderRadius: "30px",
		boxShadow: "0px 0px 5px #888888",
		margin: ".5vw",
		width: "calc(1vw + 10px)",
		height: "calc(1vw + 10px)",
		padding: "0.5vw",
	},
	subTitle: {
		fontWeight: "bold",
		margin: "1vw",
		color: "gray",
		fontSize: "calc(1vw + 10px)",
		fontWeight: "bold",
	},
	cardContainer: {
		backgroundColor: "#eeeeee",
		borderRadius: "30px",
		margin: "1.5vw",
		boxShadow: "0px 5px 5px #888888",
		display: "flex",
		justifyContent: "center",
		flexGrow: "1",
	},
	cardContent: {
		flexDirection: "column",
		margin: "1vw",
		alignItems: "center",
		display: "flex",
		flexGrow: "1",
		alignContent: "center",
		justifyContent: "space-around",
	},
	checkoutButton: {
		borderRadius: "25px",
		color: "white",
		backgroundColor: "red",
		width: "200px",
		textTransform: "none",
		margin: "20px",
		fontSize: "calc(0.5vw + 10px)",
		padding: "10px",
	},
	statusLabel: {
		color: "white",
		padding: "5px",
		borderRadius: "25px",
		textAlign: "center",
		textTransform: "uppercase",
		fontSize: "calc(0.5vw + 5px)",
	},
}));

const AppDownloadRedirectDialog = (props) => {
	const classes = useStyles();
	const { open, setOpen, message } = props;

	return (
		<Dialog open={open}>
			{setOpen && (
				<div style={{ width: "100%", alignContent: "end",cursor:"pointer" }} onClick={()=>{setOpen(false)}}>
					<CloseIcon style={{marginTop:"2%",marginLeft:"94%"}} color="gray"></CloseIcon>
				</div>
			)}
			<div style={{ padding: "20px" }}>
				<Typography style={{ fontSize: "22px", textAlign: "center" }}>{message}</Typography>
			</div>
			<img
				src="https://www.freepnglogos.com/uploads/app-store-logo-png/app-store-and-play-store-logos-10.png"
				style={{ width: "400px", margin: "20px", alignSelf: "center" }}></img>
			<div
				style={{
					alignItems: "center",
					textAlign: "center",
					marginBottom: "20px",
				}}>
				<Button
					onClick={() => {
						window.open("https://apps.apple.com/br/app/prit-para-profissionais/id1466577398");
					}}
					style={{
						fontSize: "16px",
						padding: "10px 30px",
						borderRadius: "45px",
						backgroundColor: "#3689ea",
						border: "solid 1px #fff",
						color: "#fff",
						textTransform: "none",
						margin: "0 20px",
					}}
					variant="outlined">
					Baixar na App store
				</Button>
				<Button
					onClick={() => {
						window.open("https://play.google.com/store/apps/details?id=app.prit.business&hl=pt_BR&gl=US");
					}}
					style={{
						fontSize: "16px",
						padding: "10px 30px",
						borderRadius: "45px",
						backgroundColor: "#3689ea",
						border: "solid 1px #fff",
						color: "#fff",
						textTransform: "none",
						margin: "10px",
					}}
					variant="outlined">
					Baixar na play store
				</Button>
			</div>
		</Dialog>
	);
};
export default AppDownloadRedirectDialog;
