import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import ArrowBackIosRounded from "@material-ui/icons/ArrowBackIosRounded";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import ButtonCommom from "../common/Button";

const Welcome = (props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();

	const name = location?.state?.name;

	useEffect(()=>{
		if(!name){
			navigate("/");
		}
	},[name])

	return (
		<Fragment>
			<div
				style={{
					minHeight: "70%",
					display: "flex",
					alignItems: "center",
				}}
				className={classes.container}>
				<div style={{ width: "100%", margin: "60px" }}>
					<ArrowBackIosRounded
						style={{ paddingTop: "9px", float: "left", width: "35px", height: "35px" }}
						className={classes.icon}
						onClick={() => {
							navigate("/");
						}}
					/>

					<Typography>
						<strong className={classes.title}>{`Bem vindo, ${name}.`}</strong>
					</Typography>

					<Typography style={{ marginTop: "60px", marginBottom: "30px" }}>
						<strong className={classes.strongGray}>Obrigado pelo seu interrese no Prit Business.</strong>
					</Typography>

					<div>
						<Typography style={{ marginTop: "30px", marginBottom: "30px"}}>
							<strong className={classes.littleGray} >
								Para o seu negócio ficar online, organizemos como você irá se apresentar aos clientes.
							</strong>
						</Typography>

						<Typography style={{ marginTop: "10px", marginBottom: "30px"}}>
							<strong className={classes.littleGray}>
								Vamos ajudar você a padronizar rapidamente seu horário de funcionamento e os serviços que oferece.
							</strong>
						</Typography>
					</div>

					<ButtonCommom text={"Quero padronizar agora"} toSchedule={true}/>

					<Typography style={{ marginTop: "20px", marginBottom: "30px" }}>
						<strong className={classes.littleGray}>
							Você será cadastrado como plano gratuito. Conheça todos os planos e suas vantagens entrando em contato
							conosco.
						</strong>
					</Typography>
				</div>
			</div>
		</Fragment>
	);
};

export default Welcome;
