import "./App.css";
import Routers from "../../routes";
import { MixpanelProvider } from "react-mixpanel-browser";

function App() {
	return (
		<MixpanelProvider>
			<div className="App">
				<Routers />
			</div>
		</MixpanelProvider>
	);
}

export default App;
