import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Button, Typography, Container, CircularProgress  } from "@material-ui/core";
import {auth} from 'firebase/app';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from "@mui/material";
import Header from "../header";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import meAction from "../../store/actions/meAction";
import subscriptionDataAction from "../../store/actions/subscriptionDataAction";
import businessAction from "../../store/actions/businessAction";
import Modal from '@mui/material/Modal';
import {PrivacyPolicy} from '../../components/utils/index'


const Payment = () => {
  const [selectedPlan, setSelectedPlan] = useState("Mensal");
  const [selectedPlanTitle,setSelectedPlanTitle] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const subscriptionData = useSelector(state => state.subscription_data.subscription_data);
  const subscriptionCurrent = useSelector(state => state.subscription_current.subscription_current);

  const businessInfor = useSelector(state => state.business.business);
  const me = useSelector(state => state.me.me);
  const location = useLocation();

  const validationPage  = location?.state?.view;
  const isCardAndPlanUpdate = location?.state?.isCardAndPlanUpdate;

  const pathParams = window.location.pathname.split('/');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, token } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [auth, setAuth] = useState(getAuth());
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
	setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	  };


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if ((!pathParams || pathParams.length < 3) && !me) {
        if (user) {
          setIsLoading(true);
          let tokenAuth = user.accessToken;
          let config = {
            headers: {
              Authorization: "Bearer " + tokenAuth,
            },
          };
          dispatch(meAction.getMe(config));
          dispatch(businessAction.getBusiness(config));
        } else {
          setIsLoading(false);
          setAuth(getAuth());
          navigate('/login');
        }
      } else if (pathParams && pathParams.length > 3){
        let tokenAuth = pathParams[3];
        setIsLoading(true);
        let config = {
          headers: {
            Authorization: "Bearer " + tokenAuth,
          },
        };
        localStorage.setItem('accessToken', tokenAuth);
        dispatch(meAction.getMe());
        dispatch(businessAction.getBusiness(config));
      }
    });

    return () => unsubscribe();
  }, [auth, token]);

  useEffect(() => {
    const request = setInterval(() => {
      if (auth?.currentUser || pathParams) {
        let businessId = pathParams.length > 3 ? pathParams[2] : businessInfor?.id;

        setIsLoading(true);
        let config = {
          params: {
            enable: true,
            business_create_date: businessInfor?.createdAt,
            business_id: businessId,
            add_levels: false,
          },
        };

        dispatch(subscriptionDataAction.getSubscriptionData(config));
        dispatch(subscriptionDataAction.getSubscriptionCurrent(businessId));

        setIsLoading(false);
        clearInterval(request);
      } else {
        setAuth(getAuth());
      }
    }, 1000);
  }, [businessInfor]);

  useEffect(() => {
    if (!validationPage && subscriptionCurrent?.status === 'active' && !subscriptionCurrent.is_recurrence) {
      navigate("/menu/plan")
    } else if (!validationPage && subscriptionCurrent?.status === 'active') {
      navigate("/menu/plan", { state: { card: subscriptionCurrent } });
    }
  });

  const handlePlanClick = (plan, event) => {
    setSelectedPlan(plan === selectedPlan ? null : plan);
  };

  const handleClick = () => {
    if (!businessInfor?.address?.postalCode) {
      navigate("/address", { state: { id: selectedPlanTitle?.id, isCardAndPlanUpdate: isCardAndPlanUpdate  } });
    } else {
      navigate(`/menu/method/credit`, { state: { id: selectedPlanTitle?.id, isCardAndPlanUpdate: isCardAndPlanUpdate  } });
    }
      // navigate("/menu/methods", { 
      //   state: { 
      //     id: selectedPlanTitle?.id, 
      //     isCardAndPlanUpdate: isCardAndPlanUpdate 
      //   } 
      // });
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const sortedPlans = subscriptionData?.sort((a, b) => a.mountPrice - b.mountPrice);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '80%',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: '12px',
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'auto',
    '&:focus': {
      outline: 'none',
    },
  };

  if (!subscriptionCurrent) {
    return (
      <Container maxWidth="sm" style={{ 
        background: '#ffffff',
        borderRadius: 3,
        border: 0,
        color: 'white',
        minWidth: '100vw',
        minHeight: '100vh',
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" style={{ 
        background: '#ffffff',
        borderRadius: 3,
        border: 0,
        color: 'white',
        minWidth: '100vw',
        minHeight: '100vh',
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      }}>
      <Header />
      <Typography variant="h4" align="center" style={{ color: "#393762", fontSize: '20px', padding: "15% 0px 8% 0px", fontWeight: 'bold' }}>
        Escolha um dos nossos planos e fique disponível no Prit!
      </Typography>

      <div style={{ display: "flex", justifyContent: "center", marginBottom: '4%' }}>
        <Button
          variant="outlined"
          style={{
            backgroundColor: selectedPlan === "Mensal" ? "#393662" : "",
            color: selectedPlan === "Mensal" ? '#FFFFFF' : '#3E3E3E',
            borderRadius: '60px',
            border: '0'
          }}
          onClick={event => handlePlanClick('Mensal', event)}
        >
          Mensal
        </Button>

        <Button
          variant="outlined"
          style={{
            backgroundColor: selectedPlan === "Anual" ? "#393662" : "",
            color: selectedPlan === "Anual" ? '#FFFFFF' : '#3E3E3E',
            borderRadius: '60px',
            border: '0'
          }}
          onClick={event => handlePlanClick('Anual', event)}
        >
          Anual
        </Button>
      </div>

      {sortedPlans &&
        sortedPlans.map((plan) => (
          <div key={plan.id}>
            {selectedPlan === "Mensal" && plan.daysRef === 30 && (
              <Accordion
                expanded={expanded === plan.title}
                onChange={(event, isExpanded) => {
                  setSelectedPlanTitle(plan);
                  event.stopPropagation();
                  if (isExpanded) {
                    setExpanded(plan.title);
                  } else {
                    setExpanded('');
                  }
                }}
                style={{
                  margin: "3%",
                  borderRadius: "10px",
                  backgroundColor: expanded === plan.title ? '#393762' : '#E1E1E1',
                  color: expanded === plan.title ? '#fff' : '#000'
                }}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{ minWidth: '-webkit-fill-available' }}
                >
                  <Box display="flex" justifyContent="space-between" width="100%">
                    <Typography>
                      {capitalizeFirstLetter(plan.title)}
                    </Typography>
                    <Typography>
                      {(plan.mountPrice / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </Typography>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography>
                    {plan.description.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                        <br />
                      </React.Fragment>
                    ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
            {selectedPlan === "Anual" && plan.daysRef === 365 && (
              <Accordion
                expanded={expanded === plan.title}
                onChange={(event, isExpanded) => {
                  setSelectedPlanTitle(plan);
                  event.stopPropagation();
                  if (isExpanded) {
                    setExpanded(plan.title);
                  } else {
                    setExpanded('');
                  }
                }}
                style={{
                  margin: "3%",
                  borderRadius: "10px",
                  backgroundColor: expanded === plan.title ? '#393762' : '#E1E1E1',
                  color: expanded === plan.title ? '#fff' : '#000'
                }}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  style={{ minWidth: '-webkit-fill-available' }}
                >
                  <Box display="flex" justifyContent="space-between" width="100%">
                    <Typography>
                      {capitalizeFirstLetter(plan.title)}
                    </Typography>
                    <Typography>
                      {(plan.mountPrice / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {plan.description.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                        <br />
                      </React.Fragment>
                    ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        ))}

      <Typography
        variant="body2"
        align="center"
        style={{ color: "grey", marginTop: "20px", fontWeight: 'bold' }}
      >
        Ao clicar em “Contratar Plano” você concorda com nossa <span style={{ color: "#393662", cursor: "pointer" }} onClick={handleOpen}>Política de Privacidade</span>.
      </Typography>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">Política de Privacidade</h2>
          <p id="parent-modal-description">
            {PrivacyPolicy}
          </p>
        </Box>
      </Modal>

      <Button
      variant="contained"
      color="primary"
      fullWidth
      disabled={!expanded}
      style={{ 
        margin: "20px 0 40px", 
        backgroundColor: "#3688EB", 
        borderRadius: "20px", 
        fontWeight: 'bold', 
        height: '5vh' 
      }}
      onClick={handleClick}
    >
      Contratar Plano
    </Button>
    </Container>
  );
};

export default Payment;
