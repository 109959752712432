import React from 'react';
import { Button, Typography, Container } from "@material-ui/core";

import CreditsCard from "assets/images/credit-card.png";
import { useLocation, useNavigate } from 'react-router';
import Header from '../header';
import { useSelector } from 'react-redux';

const PaymentMethods = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const idPlan  = location?.state.id;
  const isCardAndPlanUpdate  = location?.state?.isCardAndPlanUpdate;
  const businessInfor = useSelector(state => state.business.business);


  const handlePaymentMethodClick = (method) => {
    if (!businessInfor?.address?.postalCode) {
      navigate("/address");
    } else {
      navigate(`/menu/method/${method}`, { state: { id: idPlan, isCardAndPlanUpdate } });
    }
  };

  return (
        <Container maxWidth="sm" style={{ 
        background: '#fff',
        borderRadius: 3,
        border: 0,
        color: 'white',
        minWidth: '100vw',
        minHeight: '100vh',
        padding: '0 30px',
        textAlign: "center" ,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      }}>
        <Header />
      <Typography variant="h4" style={{ color: "#585858", fontWeight: 'bold', fontSize: '25px', padding: "30% 0px 10% 0px" }}>
        Escolha como você quer pagar
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button 
        variant="contained" 
        onClick={() => handlePaymentMethodClick("credit")}
        style={{ backgroundColor: 'white', color: '#585858', border: '1px solid #959393', borderRadius: '40px', marginBottom: '8%', height:'100px' }}>
          <Typography  style={{ display: 'flex', flexDirection: 'column', alignItems:'center' }}>Cartão de crédito
          <img src={CreditsCard} alt="Cartão de crédito" style={{width:'36%'}}/>
          </Typography>
         
        </Button>
        <Button 
        variant="contained" 
        onClick={() =>(navigate("/menu/method/pix",  { state: idPlan }))}
        style={{ backgroundColor: 'white', color: '#585858', border: '1px solid #959393', borderRadius: '40px', marginBottom: '2%', height:'100px' }}>
          Pix
        </Button>
    </div>

      
    </Container>
  );
}
export default PaymentMethods;
